.axis text{
    fill: #4d678f;
    font : "Arial";
    font-weight:10;
}

.axis line{
    stroke: #4d678f;
    opacity: 0.5 ;
}
.axis path {
    stroke: #4d678f;
    opacity: 0.5 ;
}