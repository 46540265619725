html, body, #root, .App {
  height: 100%;
}

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
#4d678f
#344661 (darker)
*/

.carpark-card-wrapper {
  z-index: 2;
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  width: 400px;
}

.carpark-card, .carpark-card-mobile {
  /*z-index: 2;
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  width: 400px;*/
  border-radius: 8px;
  /*background-color: #FFFFFF;*/
  padding: 1.5rem;
  background-color: #FAFAFA;
  color: #4d678f;

  -webkit-box-shadow: 3px 23px 33px -24px #A3A3A3;
  -moz-box-shadow: 3px 23px 33px -24px #A3A3A3;
  box-shadow: 3px 23px 33px -24px #A3A3A3;

  -webkit-transition: display 200ms linear;
  -ms-transition: display 200ms linear;
  transition: display 200ms linear;
}

.carpark-card-mobile {
  display: none;
}

.carpark-card .badge-carpark-no, .carpark-card-mobile .badge-carpark-no {
  color: #4d678f;
  background-color: #EDEDED;
}
.carpark-card h1, .carpark-card-mobile h1 {
  font-size: 1rem;
  font-weight: 600;
}

.carpark-card-btn-group {
  margin-top: 1rem;
}

.carpark-card-btn-group-mobile {
  display: none;
  margin-bottom: .5rem;
}

.carpark-card-btn {
  display: inline-block;
  background-color: #FAFAFA;
  color: #4d678f;
  text-align: center;
  border: 0;
  
  font-size: .8rem;
  font-weight: 600;

  width: 100%;
  padding: .5rem;
  border-radius: 8px;

  -webkit-box-shadow: 3px 23px 33px -15px #A3A3A3;
  -moz-box-shadow: 3px 23px 33px -15px #A3A3A3;
  box-shadow: 3px 23px 33px -15px #A3A3A3;
}

.carpark-card-btn:focus, .carpark-card-btn:active {
  outline: none;
}

.carpark-card-btn:hover {
  background-color: #F7F7F7;
}

.carpark-card-btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.carpark-card-btn-group a:hover {
  text-decoration: none;
}

.search-bar {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 50%;
  background-color: #fafafa;
  color: #4d678f;
  z-index: 2;
  border-radius: 16px;

  -webkit-box-shadow: 3px 23px 33px -24px #A3A3A3;
  -moz-box-shadow: 3px 23px 33px -24px #A3A3A3;
  box-shadow: 3px 23px 33px -24px #A3A3A3;
}

.search-bar input[type=text] {
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  border: 0;
  color: #304769;
  font-weight: 600;
}

.search-bar input[type=text]:focus {
  outline: none;
}

.search-bar input[type=text]::placeholder {
  font-weight: normal;
  color: #4d678f;
}

.ui-autocomplete {
  position: absolute;
  left: 0;
  z-index: 100;
  border-radius: 0px 0px 16px 16px;
}

.ui-menu {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  transition: height 0.5s ease-in-out;
}

.ui-menu-item {
  color: #304769;
}

.ui-autocomplete .ui-menu-item:first-child {
  /*margin-top: 20px;*/
}

.ui-menu-item:last-child .ui-menu-item-wrapper {
  border-radius: 0px 0px 16px 16px;
}

.ui-widget-content {
  border: 0;
  background-color: #F5F5F5;
  /*background-color: #304769;*/
}

.ui-widget.ui-widget-content {
  border: 0;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 1rem;
}

.ui-state-active {
  border: 0 !important;
  color: #304769 !important;
  background-color: #EBEBEB !important;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.summary-header {
  font-size: .8rem;
  font-weight: 600;
}

.summary-info {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-pills .nav-link {
  color: #4d678f;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #EDEDED;
  color: #4d678f;
}

.nav-item a {
  color: white;
}

.pricing-header {
  font-size: .9rem;
  font-weight: 600;
  padding: .5rem 0;
  border-top: 1px solid #4d678f;
  border-bottom: 1px solid #4d678f;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.toolbar {
  position: absolute;
  top: 6rem;
  left: 1.5rem;
  background-color: #fafafa;
  color: #4d678f;
  padding: .75rem;
  border-radius: 16px;

  z-index: 2;

  -webkit-box-shadow: 3px 23px 33px -24px #A3A3A3;
  -moz-box-shadow: 3px 23px 33px -24px #A3A3A3;
  box-shadow: 3px 23px 33px -24px #A3A3A3;
}

.toolbar ul {
  list-style-type: none;
}

.toolbar ul, .toolbar li {
  margin: 0;
  padding: 0;
}

.toolbar li {
  margin-bottom: .5rem;
}

.toolbar li:last-child {
  margin-bottom: 0;
}

.toolbar .fas {
  padding: .5rem;
  cursor: pointer;
  border-radius: 4px;
}

.toolbar li .fas:hover, .toolbar li.selected .fas {
  background-color: rgba(224, 224, 224, 0.7);
}

.filter-box {
  position: absolute;
  top: 6rem;
  left: 6rem;
  background-color: #fafafa;
  color: #4d678f;
  z-index: 2;
  border-radius: 16px;
  padding: 1rem;
  font-size: .9rem;

  -webkit-box-shadow: 3px 23px 33px -24px #A3A3A3;
  -moz-box-shadow: 3px 23px 33px -24px #A3A3A3;
  box-shadow: 3px 23px 33px -24px #A3A3A3;
}

.filter-box h5 {
  font-size: .8rem;
  font-weight: 600;
}

.navigation-content img {
  cursor: pointer;
  padding: .5rem;
  border-radius: 50%;
}

.navigation-content img:hover {
  background-color: #EDEDED;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }

  .carpark-card-wrapper {
    top: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    height: 280px;
    overflow: hidden;
  }

  .carpark-card {
    display: none;
  }

  .carpark-card-mobile {
    display: block;
    height: 280px;
  }

  .carpark-card-mobile .tab-content {
    height: 140px;
  }

  .carpark-card-btn-group {
    display: none;
  }

  .carpark-card-btn-group-mobile {
    display: block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .carpark-card-btn {
    width: auto;
    padding: .5rem .8rem;
  }

  .search-bar {
    width: calc(100% - 3rem);
  }

  .ui-menu {
    width: calc(100% - 3rem);
  }

  .navigation-content img {
    padding: 0;
    background-color: #fafafa;
  }
}
